ul {
  list-style: none;
  list-style-type: none;
  padding-inline-start: 0px;
}

.bgImg {
  position: absolute;
    width: 100vw;
    background: url(https://firebasestorage.googleapis.com/v0/b/tlartdesigns.appspot.com/o/tlartdesigns-scene-landing-primary-beta.png?alt=media&token=d6b6a4ff-f097-4a7e-9caf-1a064647df2b);
    height: 1000px;
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size: ;
    /* aspect-ratio: 30; */
    align-self: center;
    background-attachment: fixed;
    background-position: center;
}

.gallery {
  display: inline-block;
  max-width: 95%;
  max-height: 100%;
  column-gap: 1.5em;
  ackground: rgba(245,245,245,0.5);
    /* margin-top: -30px; */
    padding-top: 50px;
    z-index: 10;
    position: relative;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

@media only screen and (min-width: 1024px) {
  .gallery {
    column-count: 4;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .gallery {
    column-count: 3;
  }
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
  .gallery {
    column-count: 2;
  }
}
